<template>
    <v-select
        v-model="valueLocal"
        :items="offsets"
        item-value="value"
        item-text="name"
        :label="label"
        :hide-details="hideDetails"
        :readonly="readonly"
        :required="required"
        :dense="dense"
        :outlined="outlined"
        :disabled="disabled" />
</template>

<script>
export default {
    props: {
        label: {
            type: String,
            default: null,
        },
        value: {
            type: Number,
            default: null,
        },
        required: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        dense: {
            type: Boolean,
            default: false,
        },
        outlined: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        hideDetails: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            offsets: [
                { value: -720, name: "UTC −12:00" },
                { value: -660, name: "UTC −11:00" },
                { value: -600, name: "UTC −10:00" },
                { value: -570, name: "UTC −09:30" },
                { value: -540, name: "UTC −09:00" },
                { value: -480, name: "UTC −08:00" },
                { value: -420, name: "UTC −07:00" },
                { value: -360, name: "UTC −06:00" },
                { value: -300, name: "UTC −05:00" },
                { value: -240, name: "UTC −04:00" },
                { value: -210, name: "UTC −03:30" },
                { value: -180, name: "UTC −03:00" },
                { value: -120, name: "UTC −02:00" },
                { value: -60, name: "UTC −01:00" },
                { value: 0, name: "UTC" },
                { value: 60, name: "UTC +01:00" },
                { value: 120, name: "UTC +02:00" },
                { value: 180, name: "UTC +03:00" },
                { value: 210, name: "UTC +03:30" },
                { value: 240, name: "UTC +04:00" },
                { value: 270, name: "UTC +04:30" },
                { value: 300, name: "UTC +05:00" },
                { value: 330, name: "UTC +05:30" },
                { value: 345, name: "UTC +05:45" },
                { value: 360, name: "UTC +06:00" },
                { value: 390, name: "UTC +06:30" },
                { value: 420, name: "UTC +07:00" },
                { value: 480, name: "UTC +08:00" },
                { value: 525, name: "UTC +08:45" },
                { value: 540, name: "UTC +09:00" },
                { value: 570, name: "UTC +09:30" },
                { value: 600, name: "UTC +10:00" },
                { value: 630, name: "UTC +10:30" },
                { value: 660, name: "UTC +11:00" },
                { value: 720, name: "UTC +12:00" },
                { value: 765, name: "UTC +12:45" },
                { value: 780, name: "UTC +13:00" },
                { value: 840, name: "UTC +14:00" }
            ]
        };
    },

    computed: {
        valueLocal: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    }
};
</script>
